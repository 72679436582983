import { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../providers/AuthContext';
import { API_CONFIG } from "../api/config";

export const useApi = (currentUser: any) => {
    const { jwt: contextJwt, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleResponseErrors = (error: any) => {
        if (error.response && [401, 403].includes(error.response.status)) {
            // Log out the user if their token is invalid or expired
            logout();
        }
        throw error;
    };

    const checkPermissions = (requiredRole: string | null): boolean => {
        if (!requiredRole) return true;
        return currentUser?.role.name === requiredRole;
    };

    const request = async <T extends keyof typeof API_CONFIG>(
        endpoint: T,
        params?: Record<string, any>
    ) => {
        const { requiredRole } = API_CONFIG[endpoint];
        if (!checkPermissions(requiredRole)) {
            setError('You do not have the required permissions to access this resource.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const { url, method } = API_CONFIG[endpoint];

            const jwt = localStorage.getItem('jwt') || contextJwt;
            if (!jwt) return;
            const headers = {
                Authorization: jwt ? `Bearer ${jwt}` : '',
            };

            const response = await axios.request({
                url,
                method,
                params,
                headers,
            });

            setLoading(false);
            return response.data;
        } catch (error: any) {
            setError(error.message);
            setLoading(false);
            handleResponseErrors(error);
        }
    };

    const requestCreate = async <T extends keyof typeof API_CONFIG>(
        endpoint: T,
        data: Record<string, any>
    ) => {
        const { requiredRole } = API_CONFIG[endpoint];
        if (!checkPermissions(requiredRole)) {
            setError('You do not have the required permissions to access this resource.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const { url } = API_CONFIG[endpoint];

            const jwt = localStorage.getItem('jwt') || contextJwt;
            if (!jwt) return;
            const headers = {
                Authorization: jwt ? `Bearer ${jwt}` : '',
                'Content-Type': 'application/json',
            };

            const response = await axios.post(url, { data }, { headers });

            setLoading(false);
            return response.data;
        } catch (error: any) {
            setError(error.message);
            setLoading(false);
            handleResponseErrors(error);
        }
    };

    const requestUpdate = async <T extends keyof typeof API_CONFIG>(
        endpoint: T,
        entityId: string,
        data: Record<string, any>
    ) => {
        const { requiredRole } = API_CONFIG[endpoint];
        if (!checkPermissions(requiredRole)) {
            setError('You do not have the required permissions to access this resource.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const { url } = API_CONFIG[endpoint];

            const jwt = localStorage.getItem('jwt') || contextJwt;
            if (!jwt) return;
            const headers = {
                Authorization: jwt ? `Bearer ${jwt}` : '',
                'Content-Type': 'application/json',
            };

            const response = await axios.put(`${url}/${entityId}`, { data }, { headers });

            setLoading(false);
            return response.data;
        } catch (error: any) {
            setError(error.message);
            setLoading(false);
            handleResponseErrors(error);
        }
    };

    return { loading, error, request, requestCreate, requestUpdate };
};

export default useApi;
