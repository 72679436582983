import React, { useState } from 'react';
import {
    Box,
    VStack,
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
    Heading,
    useToast,
    useDisclosure, Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Outflow } from './OutflowInterfaces';
import { useApiContext } from '../../providers/ApiProvider';
import { useUserContext } from '../../context/UserContext';
import UserSelectionModal from './UserSelectionModal';
import {UserWithCalculatedData} from "../users/UserInterfaces"; // Import the user selection modal

export const NewOutflow: React.FC = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const { createEntity } = useApiContext();
    const { currentUser, users } = useUserContext(); // Get the current user and all users
    const [outflowData, setOutflowData] = useState<Partial<Outflow>>({
        price: 0,
        concept: '',
        date: new Date(),
        entity: '',
        cuit: '',
        billType: '',
        origin: '',
        isPaidToEmployee: true,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedUser, setSelectedUser] = useState<string | null>(null);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        setOutflowData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === 'origin' && value === 'Personal') {
            onOpen();
        }
    };

    const handleUserSelect = (userId: string) => {
        setSelectedUser(userId);
        onClose();
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsSubmitting(true);

        const newOutflowData = { ...outflowData };

        if (outflowData.origin === 'Personal' && selectedUser) {
            newOutflowData.user = users.find((user: any) => user.id == selectedUser);
            newOutflowData.isPaidToEmployee = false;
        }

        try {
            const createdOutflow = await createEntity('createOutflow', newOutflowData);
            console.log('Created outflow:', createdOutflow);

            toast({
                title: 'Outflow Created',
                description: 'The outflow has been successfully created.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            navigate('/outflows');
        } catch (error) {
            console.error('Error creating outflow:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while creating the outflow.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }

        setIsSubmitting(false);
    };

    return (
        <Box maxWidth="400px" margin="0 auto">
            <Heading as="h2" size="xl" textAlign="center" mb={8}>
                New Outflow
            </Heading>
            <form onSubmit={handleSubmit}>
                <VStack spacing={4} align="stretch">
                    <FormControl isRequired>
                        <FormLabel>Date</FormLabel>
                        <Input
                            type="date"
                            name="date"
                            value={typeof outflowData.date === 'string' ? outflowData.date : (outflowData.date?.toISOString().split('T')[0] || '')}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Entity</FormLabel>
                        <Input
                            type="text"
                            name="entity"
                            value={outflowData.entity || ''}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>CUIT</FormLabel>
                        <Input
                            type="text"
                            name="cuit"
                            value={outflowData.cuit || ''}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Concept</FormLabel>
                        <Input
                            type="text"
                            name="concept"
                            value={outflowData.concept || ''}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Tipo fact</FormLabel>
                        <Select
                            name="billType"
                            value={outflowData.billType || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Select bill type</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="Desconocida">Desconocida</option>
                        </Select>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Price</FormLabel>
                        <Input
                            type="number"
                            name="price"
                            value={outflowData.price || ''}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Origin</FormLabel>
                        <Select
                            name="origin"
                            value={outflowData.origin || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Select origin</option>
                            <option value="MercadoPago">MercadoPago</option>
                            <option value="Cash">Cash</option>
                            <option value="Transferencia">Transferencia</option>
                            <option value="Personal">Personal</option>
                        </Select>
                    </FormControl>
                    <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
                        Create Outflow
                    </Button>
                </VStack>
            </form>
            <UserSelectionModal
                isOpen={isOpen}
                onClose={onClose}
                onSelectUser={handleUserSelect}
                users={users.filter(user => user.role.name === 'Employee' || user.role.name === 'Admin')}
            />
            <Text>
                {users.find((x: UserWithCalculatedData) => x.id.toString() == selectedUser)?.name}
            </Text>
        </Box>
    );
};
