import React, { createContext, useContext, useState, useEffect } from 'react';
import { Outflow } from '../components/outflows/OutflowInterfaces'; // Adjust the path as necessary
import { useApiContext } from '../providers/ApiProvider';

interface OutflowContextType {
    outflows: Outflow[];
    loading: boolean;
    error: string | null;
    createOutflow: (newOutflow: Partial<Outflow>) => Promise<void>;
    updateOutflow: (outflowId: number, updatedFields: Partial<Outflow>) => Promise<void>;
}

const OutflowContext = createContext<OutflowContextType | undefined>(undefined);

export const useOutflowContext = () => {
    const context = useContext(OutflowContext);
    if (!context) {
        throw new Error("useOutflowContext must be used within an OutflowProvider");
    }
    return context;
};

export const OutflowProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [outflows, setOutflows] = useState<Outflow[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { getAllPages, createEntity, updateEntity } = useApiContext();

    useEffect(() => {
        const fetchOutflows = async () => {
            try {
                setLoading(true);
                const response = await getAllPages('outflows', {
                    pagination: { page: 1, pageSize: 50 },
                    populate: {
                        user: {
                            fields: '*',
                            populate: {
                                role: "*"
                            }
                        },
                    },
                });
                if (!response)
                    return

                const mappedOutflows = response.map((item:any) => {
                    const userData = item.attributes.user.data;
                    let response;
                    if (userData) {
                        const modifiedUser = {
                            ...userData.attributes,
                            role: userData.attributes.role.data.attributes
                        }
                        response =  {
                            id: item.id,
                            ...item.attributes,
                            user: modifiedUser
                        }
                    } else {
                        response =  {
                            id: item.id,
                            ...item.attributes
                        }
                    }
                    return response
                })
                // const mappedOutflows = response.map((item: any) => ({
                //     // id: item.id,
                //     user: {
                //         role: item.user.data.attributes.role.data.attributes,
                //         ...item.user.data.attributes
                //     },
                //     ...item.attributes
                // }));
                setOutflows(mappedOutflows);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching outflows:', err);
                setError('Failed to fetch outflows');
                setLoading(false);
            }
        };

        fetchOutflows();
    }, []);

    const createOutflow = async (newOutflow: Partial<Outflow>) => {
        try {
            setLoading(true);
            const response = await createEntity('createOutflow', { body: newOutflow });
            if (response !== undefined) {
                setOutflows(prev => [...prev, response]);
            } else {
                throw new Error('No valid response from the create operation');
            }
            setLoading(false);
        } catch (err) {
            console.error('Error creating outflow:', err);
            setError('Failed to create outflow');
            setLoading(false);
        }
    };

    const updateOutflow = async (outflowId: number, updatedFields: Partial<Outflow>) => {
        try {
            setLoading(true);
            const response = await updateEntity('outflows', outflowId.toString(), updatedFields);
            if (response !== undefined) {
                setOutflows(prev =>
                    prev.map(outflow =>
                        outflow.id === outflowId ? { ...outflow, ...updatedFields } : outflow
                    )
                );
            } else {
                throw new Error('No valid response from the update operation');
            }
            setLoading(false);
        } catch (err) {
            console.error('Error updating outflow:', err);
            setError('Failed to update outflow');
            setLoading(false);
        }
    };

    return (
        <OutflowContext.Provider value={{ outflows, loading, error, createOutflow, updateOutflow }}>
            {children}
        </OutflowContext.Provider>
    );
};
