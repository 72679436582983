import React, {useState} from 'react';
import {Box, Flex, Stat, StatLabel, StatNumber} from '@chakra-ui/react';
import {DispenseHistory} from "./DispenseInterfaces";
import { useDispenseHistoryContext } from '../../context/DispenseHistoryContext';
import CustomizableTable from '../customizable-table/CustomizableTable';
import {User} from "../users/UserInterfaces";
const dispenseHistoryFields : any = {
    id: {
        label: 'ID',
        defaultVisible: true,
        disableFilter: true

    },
    'user.username': {
        label: 'User',
        defaultVisible: true,
        filterType: 'string',
        disableFilter: true

    },
    'user.email': {
        label: 'Email',
        defaultVisible: true,
        filterType: 'string',
        disableFilter: true

    },
    'user.dni': {
        label: 'DNI',
        defaultVisible: true,
        filterType: 'string',
        disableFilter: true

    },
    'product.Name': {
        label: 'Product',
        defaultVisible: true,
        filterType: 'string',
        disableFilter: true

    },
    dateDispensed: {
        label: 'Date Dispensed',
        defaultVisible: true,
        filterType: 'dateRange'
    },
    units: {
        label: 'Units',
        defaultVisible: true,
        filterType: 'range',
        filterMin: 0,
        filterMax: 1000 // Adjust maximum as needed
    },
    price: {
        label: 'Price',
        defaultVisible: true,
        filterType: 'range',
        filterMin: 0,
        filterMax: 100000 // Adjust maximum as needed
    },
    paymentMethod: {
        label: 'Payment Method',
        defaultVisible: true,
        filterType: 'enum',
        filterOptions: ['MercadoPago', 'Transferencia', 'Cash'] // Example options
    }
};

const DispenseHistoriesView: React.FC = () => {
    const { dispenseHistories, loading, error } = useDispenseHistoryContext();
    const [filteredSortedData, setFilteredSortedData] = useState<DispenseHistory[]>([]);

    if (loading) {
        return <Box>Loading dispense histories...</Box>;
    }

    if (error) {
        return <Box>Error: {error}</Box>;
    }
    const totalSpent = filteredSortedData.reduce((total, history) => total + history.precio, 0);
    const totalUnitsCannabis = filteredSortedData
        .filter(history => history.product.productType.toLowerCase() === 'cannabis')
        .reduce((total, history) => total + history.units, 0);

    const handleDataChange = (data: DispenseHistory[]) => {
        setFilteredSortedData(data);
    };

    // Transform dispense history data for the table
    const data: DispenseHistory[] = dispenseHistories.map((history: DispenseHistory) => ({
        ...history,
        user: history.user,
        email: history.user.email,
        dni: history.user.dni,
        genetic: history.genetic,
        dateDispensed: history.dateDispensed,
        units: history.units,
        price: history.precio,
        paymentMethod: history.medioDePago
    }));

    return (
        <Box>
            <Flex justify="space-around" mb={8}>
                <Stat>
                    <StatLabel>Total Spent</StatLabel>
                    <StatNumber>${totalSpent.toFixed(2)}</StatNumber>
                </Stat>
                <Stat>
                    <StatLabel>Total Units (Cannabis)</StatLabel>
                    <StatNumber>{totalUnitsCannabis}</StatNumber>
                </Stat>
            </Flex>
            <CustomizableTable<DispenseHistory>
                data={data}
                onDataChange={handleDataChange}
                fields={dispenseHistoryFields}
            />
        </Box>
    );
};

export default DispenseHistoriesView;
