// ConfigurationPanel.tsx

import React, { useState } from 'react';

import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    Checkbox,
    Box,
    Input,
    Button,
    Flex,
    IconButton,
    Tooltip,
} from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';

interface ConfigurationPanelProps<T> {
    isOpen: boolean;
    onClose: () => void;
    fields: Record<keyof T, { label: string; defaultVisible?: boolean; filterField?: keyof T }>;
    visibleFields: (keyof T)[];
    setVisibleFields: React.Dispatch<React.SetStateAction<(keyof T)[]>>;
    handleFieldToggle: (field: keyof T) => void;
}

function ConfigurationPanel<T extends Record<string, any>>({
                                                               isOpen,
                                                               onClose,
                                                               fields,
                                                               visibleFields,
                                                               setVisibleFields,
                                                               handleFieldToggle,
                                                           }: ConfigurationPanelProps<T>) {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredFields = Object.entries(fields).filter(([field, config]) =>
        config.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Configure Table</DrawerHeader>
                <DrawerBody>
                    <Input
                        placeholder="Search fields..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        mb={4}
                    />

                    {filteredFields.map(([field, config]) => (
                        <Flex key={field} alignItems="center" mb={2}>
                            <Checkbox
                                isChecked={visibleFields.includes(field as keyof T)}
                                onChange={() => handleFieldToggle(field as keyof T)}
                            >
                                {config.label}
                            </Checkbox>
                            {config.filterField && (
                                <Tooltip label={`Filter by ${fields[config.filterField as keyof T].label}`}>
                                    <IconButton
                                        aria-label={`Filter by ${fields[config.filterField as keyof T].label}`}
                                        icon={<QuestionOutlineIcon />}
                                        size="sm"
                                        variant="ghost"
                                        ml={2}
                                    />
                                </Tooltip>
                            )}
                        </Flex>
                    ))}

                    <Button onClick={() => setVisibleFields(Object.keys(fields) as (keyof T)[])}>
                        Reset to Default
                    </Button>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}

export default ConfigurationPanel;