import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface AuthContextType {
    jwt: string | null;
    login: (jwt: string) => void;
    logout: () => void;
}

interface AuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [jwt, setJwt] = useState<string | null>(localStorage.getItem('jwt'));

    const login = (newJwt: string) => {
        setJwt(newJwt);
        console.log("Setted jwt")
        localStorage.setItem('jwt', newJwt);
    };

    const logout = () => {
        setJwt(null);
        console.log("Removed jwt")
        localStorage.removeItem('jwt');
    };

    return (
        <AuthContext.Provider value={{ jwt, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};