import React, { createContext, useContext, useState, useEffect } from 'react';
import useApi from '../hooks/apiHook';
import { useAuth } from './AuthContext';
import { API_CONFIG } from "../api/config";

interface ApiData {
    [key: string]: any;
}

interface CacheEntry {
    data: any;
    timestamp: number;
    ttl: number;  // time to live in milliseconds
}

interface ApiContextType {
    data: ApiData | null;
    loading: boolean;
    error: string | null;
    fetchData: <T>(endpoint: keyof typeof API_CONFIG, params?: any, cacheDuration?: number) => Promise<T | void>;
    getAllPages: (endpoint: keyof typeof API_CONFIG, params: any, accumulatedData?: Set<any>) => Promise<any>;
    createEntity: (entity: keyof typeof API_CONFIG, entityData: any) => Promise<any>;
    updateEntity: (entity: keyof typeof API_CONFIG, entityId: string, entityData: any) => Promise<any>;
}

const ApiContext = createContext<ApiContextType | undefined>(undefined);

export const useApiContext = (): ApiContextType => {
    const context = useContext(ApiContext);
    if (!context) throw new Error("useApiContext must be used within an ApiProvider");
    return context;
};

interface ApiProviderProps {
    children: React.ReactNode;
    currentUser: any;
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ children, currentUser }) => {
    const [data, setData] = useState<ApiData | null>(null);
    const [cache, setCache] = useState<{ [key: string]: CacheEntry }>({});
    const { loading, error, request, requestCreate, requestUpdate } = useApi(currentUser);
    const { jwt } = useAuth();
    const [requestQueue, setRequestQueue] = useState<(() => void)[]>([]);
    const [isCurrentUserLoaded, setIsCurrentUserLoaded] = useState(!!currentUser);

    useEffect(() => {
        if (currentUser && !isCurrentUserLoaded) {
            setIsCurrentUserLoaded(true);
            processQueue();
        }
    }, [currentUser]);

    const checkPermissions = (requiredRole: string | null, endpoint: string | null = null): boolean => {
        if (jwt && endpoint === 'me') return true;
        if (!currentUser) return false;
        if (!requiredRole) return true;
        return currentUser?.role.name === requiredRole;
    };

    const processQueue = () => {
        requestQueue.forEach((queuedRequest) => queuedRequest());
        setRequestQueue([]);
    };

    const queueRequest = (requestFunction: () => void) => {
        setRequestQueue((prevQueue) => [...prevQueue, requestFunction]);
    };

    const getAllPages = async (endpoint: keyof typeof API_CONFIG, params: any, accumulatedData: Set<any> = new Set(), cacheDuration = 300000): Promise<any> => {
        if (!isCurrentUserLoaded && endpoint !== 'me') {
            queueRequest(() => getAllPages(endpoint, params, accumulatedData, cacheDuration));
            return;
        }

        const { requiredRole } = API_CONFIG[endpoint];
        if (!checkPermissions(requiredRole, endpoint)) {
            console.log('You do not have the required permissions to access this resource.');
            return;
        }

        const cacheKey = `${endpoint}_${JSON.stringify(params)}`;
        let allData: any[] = Array.from(accumulatedData);
        let currentPage = params.pagination?.page || 0;
        let hasMoreData = true;

        while (hasMoreData) {
            const pageCacheKey = `${cacheKey}_page${currentPage}`;
            const pageCacheEntry = cache[pageCacheKey];
            const now = new Date().getTime();

            if (pageCacheEntry && now - pageCacheEntry.timestamp < pageCacheEntry.ttl) {
                allData = [...allData, ...pageCacheEntry.data];
            } else {
                const response = await request(endpoint, { ...params, pagination: { ...params.pagination, page: currentPage } });
                const responseData = response?.data || response;
                const dataArray = Array.isArray(responseData) ? responseData : [responseData];

                allData = [...allData, ...dataArray];
                const newPageEntry = { data: dataArray, timestamp: now, ttl: cacheDuration };
                setCache((prev) => ({ ...prev, [pageCacheKey]: newPageEntry }));
            }

            currentPage++;
            hasMoreData = allData.length === (currentPage - params.pagination?.page) * params.pagination?.pageSize;
        }

        return allData;
    };

    const fetchData = async (endpoint: keyof typeof API_CONFIG, params: any = {}, cacheDuration = 300000, skipCache = false) => {
        if (!isCurrentUserLoaded && endpoint !== 'me') {
            queueRequest(() => fetchData(endpoint, params, cacheDuration, skipCache));
            return;
        }

        const { requiredRole } = API_CONFIG[endpoint];
        if (!checkPermissions(requiredRole, endpoint)) {
            console.log('You do not have the required permissions to access this resource.');
            return;
        }

        const now = new Date().getTime();
        const cacheKey = `${endpoint}_${JSON.stringify(params)}`;
        const cacheEntry = cache[cacheKey];

        if (!skipCache && cacheEntry && now - cacheEntry.timestamp < cacheEntry.ttl) {
            return cacheEntry.data;
        }

        try {
            const response = await request(endpoint, params);
            const newEntry = { data: response, timestamp: now, ttl: cacheDuration };
            setCache((prev) => ({ ...prev, [cacheKey]: newEntry }));
            return response;
        } catch (error) {
            console.error(`Error fetching data for ${endpoint}:`, error);
            throw error;
        }
    };

    const createEntity = async (entity: keyof typeof API_CONFIG, entityData: any) => {
        if (!isCurrentUserLoaded) {
            queueRequest(() => createEntity(entity, entityData));
            return;
        }

        const { requiredRole } = API_CONFIG[entity];
        if (!checkPermissions(requiredRole)) {
            console.log('You do not have the required permissions to access this resource.');
            return;
        }

        try {
            const response = await requestCreate(entity, entityData);
            return response.data;
        } catch (error) {
            console.error(`Error creating entity: ${entity}`, error);
            throw error;
        }
    };

    const updateEntity = async (entity: keyof typeof API_CONFIG, entityId: string, entityData: any) => {
        if (!isCurrentUserLoaded) {
            queueRequest(() => updateEntity(entity, entityId, entityData));
            return;
        }

        const { requiredRole } = API_CONFIG[entity];
        if (!checkPermissions(requiredRole)) {
            console.log('You do not have the required permissions to access this resource.');
            return;
        }

        try {
            const response = await requestUpdate(entity, entityId, entityData);
            return response.data;
        } catch (error) {
            console.error(`Error updating entity: ${entity}`, error);
            throw error;
        }
    };

    return (
        <ApiContext.Provider value={{ data, loading, error, fetchData, getAllPages, createEntity, updateEntity }}>
            {children}
        </ApiContext.Provider>
    );
};
