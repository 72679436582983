import { Box, Image, Text, Badge, useColorModeValue, Stack } from '@chakra-ui/react';
import { Product } from '../dispense/DispenseInterfaces';

const ProductCard = ({ product }: { product: Product }) => {
    const cardBg = useColorModeValue('white', 'gray.800');
    const imageUrl = `/path/to/images/${product.id}.jpg` || 'path/to/default.jpg'; // Ensure you have a default image

    // Color coding for stock level
    const stockLevel = (product.stockInGrams && product.stockInGrams > 0) ? product.stockInGrams : 0;
    let stockColor = 'green';
    if (stockLevel <= 0) {
        stockColor = 'red';

    } else if (stockLevel > 0 && stockLevel <= 5) {
        stockColor = 'orange';
    }

    return (
        <Box bg={cardBg} boxShadow="md" borderRadius="lg" overflow="hidden" transition="all 0.3s ease" _hover={{ boxShadow: 'xl' }}>
            {/*<Image src={imageUrl} alt={product.Name} height="200px" width="100%" objectFit="cover" />*/}
            <Box p="5">
                <Text mt="1" fontWeight="bold" fontSize="lg" lineHeight="tight" isTruncated>
                    {product.Name}
                </Text>
                <Stack direction="row" mb={1} align="baseline">
                    <Badge borderRadius="full" px="2" colorScheme="teal">
                        {product.productType}
                    </Badge>
                    <Badge px="2" colorScheme={stockColor} ml={1}>
                        {stockLevel} in stock
                    </Badge>
                </Stack>
                <Text fontWeight="semibold" color="gray.600">
                    ${product.price.toFixed(2)} / unit
                </Text>
                <Text fontSize="sm" color="gray.500" mt={2}>
                    {product.Description}
                </Text>
            </Box>
        </Box>
    );
};

export default ProductCard;
