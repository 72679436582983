// api/userApi.ts
import axios from 'axios';
import { API_CONFIG } from '../api/config';

export const fetchCurrentUser = async (jwt: string) => {
    const { url } = API_CONFIG.me;
    const headers = {
        Authorization: `Bearer ${jwt}`,
    };

    const response = await axios.get(url, { headers });
    return response.data;
};
