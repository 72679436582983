import React from 'react';
import {
    VStack, Card, CardBody, FormControl, FormLabel, Input, Select, Text, IconButton, Flex, Button, Checkbox
} from '@chakra-ui/react';
import { AddIcon, EditIcon, MinusIcon } from '@chakra-ui/icons';
import { DispenseFormData, Product } from "./DispenseInterfaces";

interface DispenseFormProps {
    dispenseData: DispenseFormData[];
    medioDePago: string;
    productOptions: Product[];
    user: any;
    onInputChange: (index: number, field: keyof DispenseFormData, value: string) => void;
    onAddDispense: () => void;
    onRemoveDispense: (index: number) => void;
    onMedioDePagoChange: (value: string) => void;
    onSubmit: () => void;
    totalPrice: number;
    errors: { [key: string]: boolean };
    isProcessing: boolean; // Added to handle the loading state
    showComment: boolean; // Added to handle the comment field visibility
    onShowCommentChange: (value: boolean) => void; // Handler for comment checkbox
}

export const DispenseForm: React.FC<DispenseFormProps> = ({
                                                              dispenseData,
                                                              medioDePago,
                                                              productOptions,
                                                              user,
                                                              onInputChange,
                                                              onAddDispense,
                                                              onRemoveDispense,
                                                              onMedioDePagoChange,
                                                              onSubmit,
                                                              totalPrice,
                                                              errors,
                                                              isProcessing,
                                                              showComment,
                                                              onShowCommentChange,
                                                          }) => {
    return (
        <>
            <VStack spacing={4} align="stretch">
                {dispenseData.map((dispense, index) => (
                    <Card key={index}>
                        <CardBody>
                            <VStack spacing={4} align="stretch">
                                <FormControl isRequired isInvalid={errors[`grams-${index}`]}>
                                    <FormLabel>Units</FormLabel>
                                    <Input
                                        type="number"
                                        value={dispense.grams || ''}
                                        onChange={(e) => onInputChange(index, 'grams', e.target.value)}
                                    />
                                </FormControl>
                                <FormControl isRequired isInvalid={errors[`genetic-${index}`]}>
                                    <FormLabel>Product</FormLabel>
                                    <Select
                                        value={dispense.genetic}
                                        onChange={(e) => onInputChange(index, 'genetic', e.target.value)}
                                    >
                                        <option value="">Select Genetic</option>
                                        {productOptions.map((genetic) => (
                                            <option key={genetic.id} value={genetic.id}>
                                                {genetic.Name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Flex justify="space-between" align="center">
                                    {dispense.isEditingPrice ? (
                                        <FormControl>
                                            <Input
                                                type="number"
                                                value={dispense.price || ''}
                                                onChange={(e) => onInputChange(index, 'price', e.target.value)}
                                                size="sm"
                                                width="100px"
                                            />
                                        </FormControl>
                                    ) : (
                                        <Text fontWeight="bold">
                                            Creditos: {dispense.price}{' '}
                                            <IconButton
                                                aria-label="Edit Price"
                                                icon={<EditIcon />}
                                                onClick={() => onInputChange(index, 'isEditingPrice', 'true')}
                                                variant="ghost"
                                                colorScheme="blue"
                                                size="sm"
                                            />
                                        </Text>
                                    )}
                                    {index > 0 && (
                                        <IconButton
                                            aria-label="Remove Dispense"
                                            icon={<MinusIcon />}
                                            onClick={() => onRemoveDispense(index)}
                                            variant="ghost"
                                            colorScheme="red"
                                            size="sm"
                                        />
                                    )}
                                </Flex>
                                <FormControl>
                                    <Checkbox
                                        isChecked={showComment}
                                        onChange={(e) => onShowCommentChange(e.target.checked)}
                                    >
                                        Add Comment
                                    </Checkbox>
                                </FormControl>
                                {showComment && (
                                    <FormControl>
                                        <FormLabel>Comments</FormLabel>
                                        <Input
                                            type="text"
                                            value={dispense.comments || ''}
                                            onChange={(e) => onInputChange(index, 'comments', e.target.value)}
                                        />
                                    </FormControl>
                                )}
                            </VStack>
                        </CardBody>
                    </Card>
                ))}
            </VStack>
            <Flex mt={4} justify="center">
                <IconButton
                    aria-label="Add Dispense"
                    icon={<AddIcon />}
                    onClick={onAddDispense}
                    variant="outline"
                    colorScheme="blue"
                    borderRadius="full"
                    boxShadow="md"
                />
            </Flex>
            <FormControl mt={8} isRequired isInvalid={errors.medioDePago}>
                <FormLabel>Medio de Pago</FormLabel>
                <Select
                    value={medioDePago}
                    onChange={(e) => onMedioDePagoChange(e.target.value)}
                >
                    <option value="">Select Medio de Pago</option>
                    <option value="MercadoPago">MercadoPago</option>
                    <option value="PosNET">PosNET</option>
                    <option value="Cash">Cash</option>
                    <option value="Transferencia">Transferencia</option>
                </Select>
            </FormControl>
            <Text fontSize="xl" fontWeight="bold" mt={4}>
                Creditos Totales: ${totalPrice}
            </Text>
            <Button
                onClick={onSubmit}
                size="lg"
                colorScheme="green"
                mt={4}
                isLoading={isProcessing} // Show spinner when processing
                isDisabled={isProcessing} // Disable button when processing
            >
                Submit
            </Button>
        </>
    );
};
