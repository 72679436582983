// DispenseConfirmation.tsx
import React from 'react';
import { Flex, Text, Grid, Box, Button } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { DispenseFormData, Product } from "./DispenseInterfaces";

interface DispenseConfirmationProps {
    user: any;
    dispenseData: DispenseFormData[];
    totalPrice: number;
    navigate: (path: string) => void;
    productOptions: Product[];
}

export const DispenseConfirmation: React.FC<DispenseConfirmationProps> = ({
                                                                              user,
                                                                              dispenseData,
                                                                              totalPrice,
                                                                              navigate,
                                                                              productOptions,
                                                                          }) => {
    const getGeneticName = (genetic: Product | null) => {
        return genetic ? genetic.Name : '';
    };

    return (
        <Flex direction="column" align="center" justify="center" minHeight="400px">
            <CheckIcon boxSize={20} color="green.500" mb={8} />
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
                Dispense for {user.username} completed
            </Text>
            <Text fontSize="xl" fontWeight="bold" mb={8}>
                Total Price: ${totalPrice}
            </Text>
            <Grid templateColumns="repeat(3, 1fr)" gap={4} mb={8}>
                {dispenseData.map((dispense, index) => (
                    <Box key={index} borderWidth={1} borderRadius="md" p={4}>
                        <Text>
                            Genetic: {getGeneticName(productOptions.find((option) => option.id === dispense.genetic) || null)}
                        </Text>
                        <Text>Grams: {dispense.grams}</Text>
                        <Text>Price: ${dispense.price}</Text>
                    </Box>
                ))}
            </Grid>
            <Button onClick={() => navigate('/select-user')}>
                New Dispense
            </Button>
        </Flex>
    );
};