import React from 'react';
import { Box, Heading, Text, VStack, HStack, Badge, Stat, StatLabel, StatNumber, Divider } from '@chakra-ui/react';
import {User} from "./UserInterfaces";
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';


export const UserView = () => {
    const { id: userId } = useParams();
    const { users } = useUserContext();
    const user = users.find(u => u.id.toString() == userId);

    if (!user) {
        return <p>User not found</p>;
    }
    return (
        <VStack spacing={4} align="stretch" p={5}>
            <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
                <Heading as="h2" size="lg">{user.name} {user.lastName}</Heading>
                <Text fontSize="sm">{user.username} | {user.email}</Text>
                <HStack justify="space-between" mt={3}>
                    <Badge colorScheme={user.OnboardingState === 'Completed' ? 'green' : 'red'}>
                        {user.OnboardingState}
                    </Badge>
                    <Text fontSize="sm">Plan: {user.Plan}</Text>
                </HStack>
            </Box>

            <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
                <Heading as="h3" size="md">Activity Details</Heading>
                <Divider my={2} />
                <HStack justify="space-between">
                    <Stat>
                        <StatLabel>Total Spent</StatLabel>
                        <StatNumber>${user.totalSpent}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Visits</StatLabel>
                        <StatNumber>{user.amountVisits}</StatNumber>
                    </Stat>
                </HStack>
                <Text mt={2}>Last Visit: {new Date(user.lastVisit).toLocaleDateString()}</Text>
                <Text>First Visit: {new Date(user.firstVisit).toLocaleDateString()}</Text>
            </Box>

            <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
                <Heading as="h3" size="md">Last Dispensed Genetic</Heading>
                <Divider my={2} />
                <VStack align="start">
                    <Text fontWeight="bold">{user.lastDispensedProduct?.Name}</Text>
                    <Text fontSize="sm">{user.lastDispensedProduct?.Description}</Text>
                    <Text fontSize="sm">Stock: {user.lastDispensedProduct?.stockInGrams} grams</Text>
                </VStack>
            </Box>
        </VStack>
    );
};