// OriginModal.tsx
import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Select } from '@chakra-ui/react';

interface OriginModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (origin: string) => void;
}

const OriginModal: React.FC<OriginModalProps> = ({ isOpen, onClose, onSubmit }) => {
    const [selectedOrigin, setSelectedOrigin] = useState('');

    const handleSubmit = () => {
        if (selectedOrigin) {
            onSubmit(selectedOrigin);
            onClose();
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select Origin</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Select placeholder="Select origin" value={selectedOrigin} onChange={(e) => setSelectedOrigin(e.target.value)}>
                        <option value="MercadoPago">MercadoPago</option>
                        <option value="Transferencia">Transferencia</option>
                        <option value="Cash">Cash</option>
                        <option value="Personal">Personal</option>
                    </Select>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                        Submit
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default OriginModal;
