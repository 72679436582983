import React, { useState, useEffect } from 'react';
import {
    Box,
    Table as ChakraTable,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Flex,
    Select,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    SearchIcon,
    CalendarIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    ChevronRightIcon, ChevronLeftIcon
} from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { sortData, getNestedValue } from '../../utils/tableUtils';
import { exportToCSV, exportToXLSX } from '../../utils/exportToCSV'; // Updated import
import DownloadButton from '../customizable-table/DownloadButton'; // Import the new component

export interface TableProps<T> {
    data: T[];
    fields: Record<keyof T, { label: string; defaultVisible?: boolean }>;
    onRowClick?: (item: T) => void;
    onDataChange?: (data: T[]) => void;
}

const UsersTable = <T extends { dispense_histories: any[] }>({ data, fields, onRowClick, onDataChange }: TableProps<T>) => {
    const [visibleFields, setVisibleFields] = useState<(keyof T)[]>(() =>
        Object.keys(fields).filter(key => (fields[key as keyof T]?.defaultVisible !== false)) as (keyof T)[]
    );
    const [sortField, setSortField] = useState<keyof T | ''>('');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [dispenseFilter, setDispenseFilter] = useState('All');

    const handleSearch = (term: string) => {
        setSearchTerm(term);
        setCurrentPage(1);
    };

    const handlePageChange = (page: number) => {
        const totalPages = Math.ceil(filteredData.length / rowsPerPage);
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleRowsPerPageChange = (value: string) => {
        setRowsPerPage(parseInt(value, 10));
        setCurrentPage(1);
    };

    const handleDateChange = (start: Date | null, end: Date | null) => {
        setStartDate(start);
        setEndDate(end);
        setCurrentPage(1);
    };

    const filterDispense = (user: T) => {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        const hasDispenseThisMonth = user.dispense_histories.some(history => {
            const dispenseDate = new Date(history.dateDispensed);
            return dispenseDate.getMonth() === currentMonth && dispenseDate.getFullYear() === currentYear;
        });
        if (dispenseFilter === 'Dispensed this month') return hasDispenseThisMonth;
        if (dispenseFilter === 'Not dispensed this month') return !hasDispenseThisMonth;
        return true;
    };

    const filteredData = data.filter(filterDispense).map(user => {
        const filteredHistories = user.dispense_histories.filter(history => {
            const dateDispensed = new Date(history.dateDispensed);
            return (
                (!startDate || dateDispensed >= startDate) &&
                (!endDate || dateDispensed <= endDate)
            );
        });
        const totalSpent = filteredHistories.reduce((sum, history) => sum + history.precio, 0);
        const totalConsumption = filteredHistories.reduce((sum, history) => sum + history.units, 0);

        return {
            ...user,
            totalSpent,
            totalConsumption
        };
    }).filter(user => {
        return visibleFields.some(field => {
            const value = getNestedValue(user, field as string);
            return value && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
        });
    });

    useEffect(() => {
        if (onDataChange) {
            onDataChange(filteredData);
        }
    }, [filteredData, onDataChange, currentPage, rowsPerPage, sortOrder, sortField, dispenseFilter, startDate, endDate]);

    const sortedData = sortData(filteredData, sortField as string, sortOrder);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedData = sortedData.slice(startIndex, endIndex);

    const handleSort = (field: keyof T) => {
        if (sortField === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };

    const handleDownload = (format: 'csv' | 'xlsx') => {
        const headers = visibleFields.map(field => ({
            key: field,
            label: fields[field].label
        }));
        if (format === 'csv') {
            exportToCSV(sortedData, headers);
        } else {
            exportToXLSX(sortedData, headers);
        }
    };

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    return (
        <Box>
            <Flex justify="space-between" align="center" mb={4} width="full">
                <InputGroup maxWidth="300px">
                    <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.300" />
                    </InputLeftElement>
                    <Input
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={e => handleSearch(e.target.value)}
                        variant="outline"
                        borderRadius="0"
                    />
                </InputGroup>
            </Flex>

            <Accordion defaultIndex={[0]} allowMultiple allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton _expanded={{ bg: 'gray.100', color: 'black' }}>
                            <Box flex="1" textAlign="left">
                                Filters
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <Flex justify="space-between" align="center" gap="20px">
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" position="absolute" left="0">
                                    <CalendarIcon color="gray.500" />
                                </InputLeftElement>
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => handleDateChange(date, endDate)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText="Start Date"
                                    customInput={<Input variant="outline" borderRadius="0" pl="30px" />}
                                    popperProps={{
                                        strategy: 'fixed', // Keeps the dropdown in view on scroll
                                    }}
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" position="absolute" left="0">
                                    <CalendarIcon color="gray.500" />
                                </InputLeftElement>
                                <DatePicker
                                    selected={endDate}
                                    onChange={date => handleDateChange(startDate, date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText="End Date"
                                    customInput={<Input variant="outline" borderRadius="0" pl="30px" />}
                                    popperProps={{
                                        strategy: 'fixed',
                                    }}
                                />
                            </InputGroup>

                            <Select onChange={e => setDispenseFilter(e.target.value)} defaultValue="All">
                                <option value="All">All</option>
                                <option value="Dispensed this month">Dispensed this month</option>
                                <option value="Not dispensed this month">Not dispensed this month</option>
                            </Select>
                            <DownloadButton onDownload={handleDownload} />
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>

            <ChakraTable mt={4}>
                <Thead>
                    <Tr>
                        {visibleFields.map(field => (
                            <Th key={field as string} onClick={() => handleSort(field)}>
                                <Flex align="center">
                                    {fields[field].label}
                                    {sortField === field && (
                                        <IconButton
                                            aria-label={`Sort ${sortOrder === 'asc' ? 'descending' : 'ascending'}`}
                                            icon={sortOrder === 'asc' ? <ChevronDownIcon /> : <ChevronUpIcon />}
                                            size="xs"
                                            ml={2}
                                            onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                                        />
                                    )}
                                </Flex>
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {paginatedData.map((item, index) => (
                        <Tr key={index} onClick={() => onRowClick && onRowClick(item)}>
                            {visibleFields.map(field => (
                                <Td key={field as string}>{getNestedValue(item, field as string)}</Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </ChakraTable>

            <Flex justify="space-between" align="center" mt={4}>
                <Select value={rowsPerPage} onChange={e => handleRowsPerPageChange(e.target.value)} width="auto">
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="50">50 rows</option>
                </Select>
                <Flex align="center">
                    <IconButton
                        aria-label="First Page"
                        icon={<ArrowLeftIcon />}
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                        mr={2}
                    />
                    <IconButton
                        aria-label="Previous Page"
                        icon={<ChevronLeftIcon />}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        mr={2}
                    />
                    <Box mx={2}>{currentPage} / {totalPages}</Box>
                    <IconButton
                        aria-label="Next Page"
                        icon={<ChevronRightIcon />}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        mr={2}
                    />
                    <IconButton
                        aria-label="Last Page"
                        icon={<ArrowRightIcon />}
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                    />
                </Flex>
            </Flex>
        </Box>
    );
};

export default UsersTable;
