import React, { createContext, useContext, useEffect, useState } from 'react';
import { DispenseHistory } from '../components/dispense/DispenseInterfaces';
import { useApiContext } from '../providers/ApiProvider';
import {visuallyHiddenStyle} from "@chakra-ui/react";

interface DispenseHistoryContextType {
    dispenseHistories: DispenseHistory[];
    loading: boolean;
    error: string | null;
}

const DispenseHistoryContext = createContext<DispenseHistoryContextType | undefined>(undefined);

export const useDispenseHistoryContext = () => {
    const context = useContext(DispenseHistoryContext);
    if (!context) {
        throw new Error("useDispenseHistoryContext must be used within a DispenseHistoryProvider");
    }
    return context;
};

export const DispenseHistoryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { getAllPages } = useApiContext();
    const [dispenseHistories, setDispenseHistories] = useState<DispenseHistory[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchDispenseHistories = async () => {
            try {
                const allHistories = await getAllPages('userDispenseHistories', {
                    pagination: { page: 1, pageSize: 100 },
                    populate: {
                        genetic: "*",
                        user: {
                            fields: ['username', 'email', 'dni']
                        }
                    }  // Update this if you also need to populate user
                });
                if (!allHistories) return
                const mappedHistories = allHistories.map((item: any) => ({
                    id: item.id,
                    dateDispensed: item.attributes.dateDispensed,
                    units: item.attributes.units,
                    medioDePago: item.attributes.medioDePago,
                    precio: item.attributes.precio,
                    createdAt: item.attributes.createdAt,
                    updatedAt: item.attributes.updatedAt,
                    publishedAt: item.attributes.publishedAt,
                    isBilled: item.attributes.isBilled,
                    user: {
                        id: item.attributes.user.data.id,
                        ...item.attributes.user.data.attributes, // Spread user attributes
                    },
                    product: {
                        id: item.attributes.genetic.data.id,
                        ...item.attributes.genetic.data.attributes, // Spread genetic attributes
                    }
                }));
                setDispenseHistories(mappedHistories);
                setLoading(false);
            } catch (err) {
                console.error("Failed to fetch dispense histories:", err);
                setError('Failed to fetch dispense histories');
                setLoading(false);
            }
        };

        fetchDispenseHistories();
    }, []);

    return (
        <DispenseHistoryContext.Provider value={{ dispenseHistories, loading, error }}>
            {children}
        </DispenseHistoryContext.Provider>
    );
};
