import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ChakraProvider, Box, Flex } from '@chakra-ui/react';
import { NewDispense } from './components/dispense/NewDispense';
import CashFlowView from './components/cash-flow/CashFlowView';
import theme from './theme';
import Login from "./components/Login";
import Sidebar from './components/Sidebar';
import { SelectUser } from "./components/users/SelectUser";
import Users from "./components/users/Users";
import Inventory from './components/inventory/Inventory';
import DispenseHistoriesView from "./components/dispense/DispenseHistoriesView";
import ProductsView from "./components/products/ProductsView";
import { UserView } from './components/users/UserView';
import { NewOutflow } from "./components/outflows/NewOutflow";
import BillsView from "./components/bills/BillsView";
import OutflowsView from "./components/outflows/OutflowsView";
import RequireAuth from "./components/RequireAuth";
import ProviderWrapper from "./providers/ProviderWrapper";

const App: React.FC = () => {
    return (
        <ProviderWrapper>
            <Flex>
                <Sidebar />
                <Box flex="1" p="4" ml={{ base: "0", md: "12" }} transition="margin 0.2s">
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/"
                            element={
                                <RequireAuth>
                                    <SelectUser redirectTo={"/new-dispense"}/>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/cash-flow"
                            element={
                                <RequireAuth>
                                    <CashFlowView />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/new-dispense"
                            element={
                                <RequireAuth>
                                    <NewDispense />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/users"
                            element={
                                <RequireAuth requiredRole="Admin">
                                    <Users />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/select-user"
                            element={
                                <RequireAuth>
                                    <SelectUser redirectTo={"/new-dispense"} />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/inventory"
                            element={
                                <RequireAuth>
                                    <ProductsView />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/dispense-histories"
                            element={
                                <RequireAuth requiredRole={'Admin'}>
                                    <DispenseHistoriesView />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/user/:id"
                            element={
                                <RequireAuth>
                                    <UserView />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/outflows"
                            element={
                                <RequireAuth requiredRole={'Admin'}>
                                    <OutflowsView />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/new-expense"
                            element={
                                <RequireAuth>
                                    <NewOutflow />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/bills"
                            element={
                                <RequireAuth requiredRole={'Admin'}>
                                    <BillsView />
                                </RequireAuth>
                            }
                        />
                        {/* Add more routes corresponding to the sidebar */}
                    </Routes>
                </Box>
            </Flex>
        </ProviderWrapper>
    );
};

export default App;
