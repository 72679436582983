import React from 'react';
import { Box, SimpleGrid, Text, Image, Badge, useColorModeValue } from '@chakra-ui/react';
import ProductCard from './ProductCard';
import { useProductContext } from '../../context/ProductContext';

const ProductsView = () => {
    const { products, loading, error } = useProductContext();

    if (loading) return <Box p={5}>Loading...</Box>;
    if (error) return <Box p={5}>Error: {error}</Box>;

    return (
        <Box padding="5">
            <Text fontSize="2xl" mb="5">Our Products</Text>
            <SimpleGrid columns={{ sm: 2, md: 3, lg: 4 }} spacing="5">
                {products.map((product) => (
                    <ProductCard key={product.id} product={product} />
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default ProductsView;
