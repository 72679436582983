// UserProvider.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useApiContext } from '../providers/ApiProvider';
import { User, UserWithCalculatedData } from '../components/users/UserInterfaces';
import { DispenseHistory, Product } from '../components/dispense/DispenseInterfaces';

interface UserContextData {
    users: UserWithCalculatedData[];
    currentUser: UserWithCalculatedData | null;
    totalConsumption: number;
    totalSpent: number;
    averageMonthlyConsumption: string;
    loading: boolean;
    error: string;
    fetchAllUsersData: () => Promise<void>;
    fetchCurrentUserData: () => Promise<void>;
}

export const UserContext = createContext<UserContextData>({
    users: [],
    currentUser: null,
    totalConsumption: 0,
    totalSpent: 0,
    averageMonthlyConsumption: '0',
    loading: true,
    error: '',
    fetchCurrentUserData: async () => {},
    fetchAllUsersData: async () => {},
});

interface UserProviderProps {
    children: React.ReactNode;
    currentUser: UserWithCalculatedData | null;
    fetchCurrentUserData: () => Promise<void>;
}

export const useUserContext = () => useContext(UserContext);

export const UserProvider: React.FC<UserProviderProps> = ({ children, currentUser, fetchCurrentUserData }) => {
    const { getAllPages } = useApiContext();
    const [users, setUsers] = useState<UserWithCalculatedData[]>([]);
    const [totalConsumption, setTotalConsumption] = useState(0);
    const [totalSpent, setTotalSpent] = useState(0);
    const [averageMonthlyConsumption, setAverageMonthlyConsumption] = useState('0');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');

    const fetchAllUsersData = async () => {
        setLoading(true);
        try {
            const usersData = await getAllPages('users', {
                populate: {
                    dispense_histories: {
                        fields: ['dateDispensed', 'units', 'precio', 'id', 'medioDePago', 'productType'],
                        populate: {
                            genetic: "*"
                        }
                    },
                    genetic: {
                        fields: ['paymentType']
                    },
                    role: {
                        fields: "*"
                    }
                },
                pagination: {
                    page: 1,
                    pageSize: 100,
                },
            });
            if (!usersData) return;
            const usersWithCalculatedData = usersData.map(processUserData);
            setUsers(usersWithCalculatedData);
            updateAggregates(usersWithCalculatedData);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching users:', err);
            setError('Failed to fetch users');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllUsersData();
    }, []);

    function updateAggregates(usersWithCalculatedData: UserWithCalculatedData[]): void {
        const totalConsumption = usersWithCalculatedData.reduce((acc, user) => acc + user.totalConsumption, 0);
        const totalSpent = usersWithCalculatedData.reduce((acc, user) => acc + user.totalSpent, 0);
        const averageMonthlyConsumption = calculateAverageMonthlyConsumption(usersWithCalculatedData);

        setTotalConsumption(totalConsumption);
        setTotalSpent(totalSpent);
        setAverageMonthlyConsumption(averageMonthlyConsumption);
    }

    function processUserData(userData: any): UserWithCalculatedData | null {
        const user: User = userData;
        let lastDispensedProduct: Product | undefined;
        let totalConsumption = 0;
        let totalSpent = 0;
        if (!user) return null;
        if (user.dispense_histories) {
            user.dispense_histories = user.dispense_histories.map(history => ({
                ...history,
                product: history.genetic,
                genetic: history.genetic,
            }));
            const dispenseHistories: DispenseHistory[] = user.dispense_histories as DispenseHistory[];

            totalConsumption = dispenseHistories.reduce((acc, history) => acc + history.units, 0);
            totalSpent = dispenseHistories.reduce((acc, history) => acc + history.precio, 0);

            const sortedHistories = dispenseHistories.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            lastDispensedProduct = sortedHistories[0]?.product;
        }

        const lastVisit = user.dispense_histories?.reduce((latest, history) => {
            const historyDate = new Date(history.dateDispensed);
            return latest > historyDate ? latest : historyDate;
        }, new Date(0)).toISOString().split('T')[0];

        return {
            ...user,
            totalConsumption,
            totalSpent,
            lastVisit,
        };
    }

    function calculateAverageMonthlyConsumption(users: UserWithCalculatedData[]): string {
        const totalConsumption = users.reduce((acc, user) => acc + user.totalConsumption, 0);
        const averageMonthlyConsumption = totalConsumption / users.length;
        return averageMonthlyConsumption.toFixed(2);
    }

    return (
        <UserContext.Provider value={{ users, currentUser, totalConsumption, totalSpent, averageMonthlyConsumption, loading, error, fetchAllUsersData, fetchCurrentUserData }}>
            {children}
        </UserContext.Provider>
    );
};
