import React, { useState } from 'react';
import { useUserContext } from '../../context/UserContext';
import { User } from './UserInterfaces';
import { useNavigate } from 'react-router-dom';
import UsersTable from './UsersTable'; // Adjust the import path as necessary

const userFields = {
    name: {
        label: 'Name',
        defaultVisible: true,
    },
    email: {
        label: 'Email',
        defaultVisible: true,
    },
    OnboardingState: {
        label: 'Onboarding State',
        defaultVisible: false,
    },
    Plan: {
        label: 'Plan',
        defaultVisible: false,
    },
    totalConsumption: {
        label: 'Total Consumption',
        defaultVisible: true,
    },
    lastVisit: {
        label: 'Last Visit',
        defaultVisible: true,
    },
    totalSpent: {
        label: 'Total Spent',
        defaultVisible: true,
    }
};

const Users = () => {
    const { users } = useUserContext();
    const navigate = useNavigate();
    const [filteredSortedData, setFilteredSortedData] = useState<User[]>([]);

    const handleRowClick = (user: User) => {
        navigate(`/user/${user.id}`);
    };

    const handleDataChange = (data: User[]) => {
        setFilteredSortedData(data);
    };

    return (
        <UsersTable
            data={users}
            fields={userFields}
            onRowClick={handleRowClick}
            onDataChange={handleDataChange}
        />
    );
};

export default Users;
