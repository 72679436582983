import React from 'react';
import { Box, VStack, Button, Icon } from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../providers/AuthContext';
import {
    AddIcon, ViewIcon, SettingsIcon, RepeatIcon, AtSignIcon, TimeIcon, CalendarIcon, UnlockIcon, ArrowForwardIcon
} from '@chakra-ui/icons';
import { useUserContext } from '../context/UserContext';

const Sidebar = () => {
    const { logout } = useAuth();
    const { currentUser } = useUserContext();
    const navigate = useNavigate();

    const routes = [
        { path: '/select-user', name: 'New Dispense', icon: AddIcon, special: true },
        { path: '/new-expense', name: 'New Expense', icon: AddIcon, special: true },
        { path: '/users', name: 'Users', icon: ViewIcon, requiredRole: 'Admin' },
        { path: '/inventory', name: 'Inventory', icon: SettingsIcon },
        { path: '/projections', name: 'Projections', icon: CalendarIcon, requiredRole: 'Admin' },
        { path: '/dispense-histories', name: 'Dispenses', icon: RepeatIcon, requiredRole: 'Admin' },
        { path: '/cash-flow', name: 'Cash Flow', icon: TimeIcon, requiredRole: 'Admin' },
        { path: '/bills', name: 'Bills', icon: AtSignIcon, requiredRole: 'Admin' },
        { path: '/outflows', name: 'Outflows', icon: ArrowForwardIcon, requiredRole: 'Admin' },
    ];

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const userHasRole = (requiredRole?: string) => {
        if (!requiredRole) return true;
        return currentUser?.role.name === requiredRole;
    };

    return (
        <Box width="250px" height="100vh" bg="gray.100" p={4}>
            <VStack spacing={4} align="stretch">
                {routes.map(route => (
                    userHasRole(route.requiredRole) && (
                        <Button
                            key={route.path}
                            as={RouterLink}
                            to={route.path}
                            colorScheme={route.special ? "green" : undefined}
                            leftIcon={<Icon as={route.icon} />}
                        >
                            {route.name}
                        </Button>
                    )
                ))}
                <Button onClick={handleLogout} leftIcon={<UnlockIcon />}>
                    Logout
                </Button>
            </VStack>
        </Box>
    );
};

export default Sidebar;
