import React, {useEffect, useState} from 'react';
import { Box, Flex, Input, InputGroup, InputLeftElement, Avatar, Text, Heading, Button, VStack, Spacer, Icon } from '@chakra-ui/react';
import { SearchIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { User } from './UserInterfaces';
import { useUserContext } from '../../context/UserContext';

interface SelectUserProps {
    redirectTo: string;
}

export const SelectUser: React.FC<SelectUserProps> = ({ redirectTo }) => {
    const { users, loading, error } = useUserContext();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        console.log("Fetching")
    }, [users]);
    const handleUserClick = (user: User) => {
        navigate(redirectTo, { state: { user } });
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) return <Box>Loading...</Box>;
    if (error) return <Box>Error: {error}</Box>;

    return (
        <Box p={5}>
            <Heading mb={6}>Select a user</Heading>
            <InputGroup mb={6}>
                <InputLeftElement pointerEvents="none" children={<Icon as={SearchIcon} color="gray.300" />} />
                <Input placeholder="Search users..." value={searchTerm} onChange={handleSearchChange} />
            </InputGroup>
            <VStack spacing={4}>
                {filteredUsers.map((user: User) => (
                    <Flex key={user.id} p={4} shadow="md" borderWidth="1px" borderRadius="lg" width="full" align="center" cursor="pointer">
                        <Avatar name={`${user.name} ${user.lastName}`} mr={4} />
                        <VStack align="left" flexGrow={1}>
                            <Text fontWeight="bold">{user.name} {user.lastName}</Text>
                            <Text fontSize="sm">{user.username}</Text>
                            <Text fontSize="sm">{user.email}</Text>
                        </VStack>
                        <Spacer />
                        <Button rightIcon={<ArrowForwardIcon />} colorScheme="green" size="sm" onClick={() => handleUserClick(user)}>
                            Select
                        </Button>
                    </Flex>
                ))}
            </VStack>
        </Box>
    );
};
