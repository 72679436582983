export function filterData<T extends Record<string, any>>(
    data: T[],
    filters: Partial<Record<string, any>>,
    searchTerm: string
): T[] {
    return data.filter(item => {
        // Filter based on provided filters
        for (const field in filters) {
            const filterValue: any = filters[field];
            const itemValue: any = getNestedValue(item, field); // Use utility function to get nested value

            // Handle date range filtering
            if (filterValue && typeof filterValue === 'object' && 'start' in filterValue && 'end' in filterValue) {
                const itemDate = new Date(itemValue);
                const startDate = new Date(filterValue.start);
                const endDate = new Date(filterValue.end);
                endDate.setHours(23, 50, 59, 999);  // Include the entire end date

                if (itemDate < startDate || itemDate > endDate) {
                    return false;
                }
            }
            // Check string includes
            else if (typeof filterValue === 'string' && typeof itemValue === 'string') {
                if (!itemValue.toLowerCase().includes(filterValue.toLowerCase())) {
                    return false;
                }
            }
            // Check array contains
            else if (Array.isArray(filterValue) && !filterValue.includes(itemValue)) {
                return false;
            }
            // Check range for numbers
            else if (typeof filterValue === 'object' && 'min' in filterValue && 'max' in filterValue) {
                if (itemValue < filterValue.min || itemValue > filterValue.max) {
                    return false;
                }
            }
        }

        // Filter based on search term across all fields
        return Object.keys(item).some(field => {
            const fieldValue = String(getNestedValue(item, field)).toLowerCase();
            return fieldValue.includes(searchTerm.toLowerCase());
        });
    });
}

export function sortData<T extends Record<string, any>>(data: T[], sortField: string, sortOrder: 'asc' | 'desc'): T[] {
    if (!sortField) return data;  // No sorting if sortField is not set
    return data.sort((a, b) => {
        const aValue = getNestedValue(a, sortField);
        const bValue = getNestedValue(b, sortField);
        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });
}


export function getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}
