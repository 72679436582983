// ProductContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { Product } from '../components/dispense/DispenseInterfaces';
import { useApiContext } from '../providers/ApiProvider';  // Adjust the path as necessary

interface ProductContextType {
    products: Product[];
    loading: boolean;
    error: string | null;
}

const ProductContext = createContext<ProductContextType | undefined>(undefined);

interface ProductProviderProps {
    children: ReactNode;
}

export const useProductContext = () => {
    const context = useContext(ProductContext);
    if (!context) {
        throw new Error("useProductContext must be used within a ProductProvider");
    }
    return context;
};

export const ProductProvider: React.FC<ProductProviderProps> = ({ children }) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { getAllPages } = useApiContext();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true);
                const fetchedProducts = await getAllPages('products', {
                    pagination: { page: 1, pageSize: 100 },  // Adjust pagination as needed
                    populate: '*'  // Assuming you want to fully populate all nested fields
                });
                if (!fetchedProducts) return
                const newProducts = fetchedProducts.map((x: any) => {
                    const id = x.id

                    return {
                        ...x.attributes,
                        id: id
                    }
                })
                setProducts(newProducts);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching products:', err);
                setError('Failed to fetch products');
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    return (
        <ProductContext.Provider value={{ products, loading, error }}>
            {children}
        </ProductContext.Provider>
    );
};
