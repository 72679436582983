export const API_CONFIG = {
    consumptionInsights: {
        url: 'https://insights.eljointclub.com/api/insights/consumption',
        method: 'GET',
        requiredRole: null,
        params: {
            startDate: '',
            endDate: '',
            numPatients: '',
        },
    },
    userDispenseHistories: {
        url: 'https://admin.eljointclub.com/api/dispense-histories',
        method: 'GET',
        requiredRole: null,
    },
    createDispense: {
        url: 'https://admin.eljointclub.com/api/dispense-histories',
        method: 'POST',
        requiredRole: null,
    },
    outflows: {
        url: 'https://admin.eljointclub.com/api/outflows',
        method: 'GET',
        requiredRole: 'Admin',
    },
    createOutflow: {
        url: 'https://admin.eljointclub.com/api/outflows',
        method: 'POST',
        requiredRole: null,
    },
    products: {
        url: 'https://admin.eljointclub.com/api/genetics',
        method: 'GET',
        requiredRole: null,
    },
    users: {
        url: 'https://admin.eljointclub.com/api/users',
        method: 'GET',
        requiredRole: null,
    },
    bills: {
        url: 'https://admin.eljointclub.com/api/bills',
        method: 'GET',
        requiredRole: 'Admin',
    },
    me: {
        url: 'https://api.eljointclub.com/me',
        method: 'GET',
        requiredRole: null,
        params: {
            populate: 'role',
        },
    }
};
