import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, useColorModeValue, VStack, Card } from '@chakra-ui/react';
import { useApiContext } from '../providers/ApiProvider';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../providers/AuthContext';

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.eljointclub.com/login', { username, password });
            login(response.data.jwt);
            navigate('/');
        } catch (error) {
            console.error('Login failed:', error);
            // Handle login error, show error message to the user
        }
    };

    // Card-like container
    const cardBg = useColorModeValue('gray.100', 'gray.700'); // Adapt color based on theme

    return (
        <Box display="flex" alignItems="center" justifyContent="center" h="100vh">
            <Box bg={cardBg} p={8} borderRadius="lg" boxShadow="md" w={['90%', '70%', '50%', '30%']}>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <FormControl>
                            <FormLabel htmlFor="username">Username</FormLabel>
                            <Input id="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="password">Password</FormLabel>
                            <Input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </FormControl>
                        <Button type="submit" colorScheme="blue" width="full" size="lg">
                            Login
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Box>
    );
};

export default Login;
