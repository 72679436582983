// theme.ts
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        brand: {
            100: '#f7fafc',
            // Add more color shades as needed
        },
    },
    // Add more theme customizations as needed
});

export default theme;