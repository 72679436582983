import React, { useEffect, useState } from 'react';
import {
    Box,
    Table as ChakraTable,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Select,
    Flex,
    useDisclosure,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    SettingsIcon,
    SearchIcon,
    ArrowLeftIcon,
    ArrowRightIcon
} from '@chakra-ui/icons';
import ConfigurationPanel from './ConfigurationPanel';
import AdvancedFilterInput from './AdvancedFilterInput';
import { sortData, filterData, getNestedValue } from '../../utils/tableUtils';
import { exportToCSV, exportToXLSX } from '../../utils/exportToCSV'; // Updated import
import DownloadButton from './DownloadButton'; // Import the new component

export interface TableProps<T> {
    data: T[];
    fields: Record<keyof T, { label: string; defaultVisible?: boolean; filterField?: keyof T, disableFilter?: boolean }>;
    onRowClick?: (item: T) => void;
    onDataChange?: (data: T[]) => void; // New callback prop
}

const CustomizableTable = <T extends Record<string, any>>({ data, fields, onRowClick, onDataChange }: TableProps<T>) => {
    const [visibleFields, setVisibleFields] = useState<(keyof T)[]>(() =>
        Object.keys(fields).filter(key => fields[key].defaultVisible !== false) as (keyof T)[]
    );
    const [filters, setFilters] = useState<Partial<Record<keyof T, any>>>({});
    const [sortField, setSortField] = useState<keyof T | ''>('');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const filteredData = filterData(data, filters, searchTerm);
    const sortedData = sortData(filteredData, sortField as string, sortOrder);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleFilterChange = (field: keyof T, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [field]: value
        }));
    };

    useEffect(() => {
        if (onDataChange) {
            onDataChange(sortedData);
        }
    }, [sortedData, onDataChange]);

    const handleDateRangeFilterChange = (field: keyof T, part: 'start' | 'end', date: Date | null) => {
        setFilters(prevFilters => {
            const existingRange = prevFilters[field] || { start: null, end: null };
            const updatedRange = {
                ...existingRange,
                [part]: date ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().substring(0, 10) : null // Adjust date to avoid timezone issues
            };
            const filters = {
                ...prevFilters,
                [field]: updatedRange
            };
            return filters;
        });
    };


    const handleFieldToggle = (field: keyof T) => {
        setVisibleFields(currentVisibleFields => {
            if (currentVisibleFields.includes(field)) {
                return currentVisibleFields.filter(f => f !== field);
            } else {
                return [...currentVisibleFields, field];
            }
        });
    };

    const handleSearch = (term: string) => {
        setSearchTerm(term);
        setCurrentPage(1);
    };

    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= Math.ceil(sortedData.length / rowsPerPage)) {
            setCurrentPage(page);
        }
    };

    const handleRowsPerPageChange = (value: string) => {
        setRowsPerPage(parseInt(value, 10));
        setCurrentPage(1);
    };

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedData = sortedData.slice(startIndex, endIndex);

    const handleDownload = (format: 'csv' | 'xlsx') => {
        const headers = visibleFields.map(field => ({
            key: field,
            label: fields[field].label
        }));
        if (format === 'csv') {
            exportToCSV(sortedData, headers);
        } else {
            exportToXLSX(sortedData, headers);
        }
    };

    const totalPages = Math.ceil(sortedData.length / rowsPerPage);

    return (
        <Box>
            <Flex justify="space-between" align="center" mb={4}>
                <InputGroup maxWidth="200px">
                    <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.300" />
                    </InputLeftElement>
                    <Input
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={e => handleSearch(e.target.value)}
                    />
                </InputGroup>
                <Flex>
                    <IconButton
                        aria-label="Configure Table"
                        icon={<SettingsIcon />}
                        onClick={onOpen}
                        mr={2}
                    />
                    <DownloadButton onDownload={handleDownload} />
                </Flex>
            </Flex>
            <ConfigurationPanel
                isOpen={isOpen}
                onClose={onClose}
                fields={fields}
                visibleFields={visibleFields}
                setVisibleFields={setVisibleFields}
                handleFieldToggle={handleFieldToggle}
            />
            <Accordion allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton bg={"gray.50"}> {/* Pale blue background on the accordion button */}
                            <Box flex="1" textAlign="left"> {/* Darker blue text for contrast */}
                                Filters
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} bg="gray.50"> {/* Even paler blue for the panel background */}
                        {Object.entries(fields).filter(([_, config]) => !config.disableFilter).map(([field, config]) => (
                            <Box key={field} mb={2} p={2} bg="white" shadow="sm"> {/* White boxes with a slight shadow for each filter */}
                                <AdvancedFilterInput
                                    field={field as keyof T}
                                    config={config}
                                    filters={filters}
                                    handleFilterChange={handleFilterChange}
                                    handleDateRangeFilterChange={handleDateRangeFilterChange}
                                />
                            </Box>
                        ))}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>

            <ChakraTable>
                <Thead>
                    <Tr>
                        {visibleFields.map(field => (
                            <Th key={field as string} onClick={() => setSortField(field)}>
                                <Flex align="center">
                                    {fields[field].label}
                                    {sortField === field && (
                                        <IconButton
                                            aria-label={`Sort ${sortOrder === 'asc' ? 'descending' : 'ascending'}`}
                                            icon={sortOrder === 'asc' ? <ChevronDownIcon /> : <ChevronUpIcon />}
                                            size="xs"
                                            ml={2}
                                            onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                                        />
                                    )}
                                </Flex>
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {paginatedData.map((item, index) => (
                        <Tr key={index} onClick={() => onRowClick && onRowClick(item)}>
                            {visibleFields.map(field => (
                                <Td key={field as string}>{getNestedValue(item, field as string)}</Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </ChakraTable>
            <Flex justify="space-between" align="center" mt={4}>
                <Select value={rowsPerPage} onChange={e => handleRowsPerPageChange(e.target.value)} width="auto">
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="50">50 rows</option>
                </Select>
                <Flex align="center">
                    <IconButton
                        aria-label="First Page"
                        icon={<ArrowLeftIcon />}
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                        mr={2}
                    />
                    <IconButton
                        aria-label="Previous Page"
                        icon={<ChevronLeftIcon />}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        mr={2}
                    />
                    <Box mx={2}>{currentPage} / {totalPages}</Box>
                    <IconButton
                        aria-label="Next Page"
                        icon={<ChevronRightIcon />}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        mr={2}
                    />
                    <IconButton
                        aria-label="Last Page"
                        icon={<ArrowRightIcon />}
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                    />
                </Flex>
            </Flex>
        </Box>
    );
};

export default CustomizableTable;
