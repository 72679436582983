import React, { createContext, useContext, useState, useEffect } from 'react';
import {Bill, BillContextType} from '../components/bills/BillInterfaces';
import { useApiContext } from '../providers/ApiProvider';

const BillContext = createContext<BillContextType | undefined>(undefined);

export const useBillContext = () => {
    const context = useContext(BillContext);
    if (!context) {
        throw new Error("useBillContext must be used within a BillProvider");
    }
    return context;
};

export const BillProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [bills, setBills] = useState<Bill[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { getAllPages , updateEntity } = useApiContext();

    useEffect(() => {
        const fetchBills = async () => {
            try {
                setLoading(true);
                const allBills = await getAllPages('bills', {
                    pagination: { page: 1, pageSize: 50 },
                    populate: {
                        user: {
                            fields: ['username', 'email', 'dni']
                        }
                    }
                });
                if (!allBills) return
                const mappedBills = allBills.map((item: any) => ({
                    id: item.id,
                    ...item.attributes,
                    user: {
                        id: item.attributes.user.data.id,
                        ...item.attributes.user.data.attributes
                    }
                }));

                setBills(mappedBills);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching bills:', err);
                setError('Failed to fetch bills');
                setLoading(false);
            }
        };

        fetchBills();
    }, []);

    const updateBill = async (billId: number, updatedBill: Partial<Bill>) => {
        try {
            await updateEntity('bills', billId.toString(), updatedBill);
            setBills(prevBills =>
                prevBills.map(bill =>
                    bill.id === billId ? { ...bill, ...updatedBill } : bill
                )
            );
        } catch (err) {
            console.error('Error updating bill:', err);
            setError('Failed to update bill');
        }
    };

    return (
        <BillContext.Provider value={{ bills, loading, error, updateBill }}>
            {children}
        </BillContext.Provider>
    );
};
