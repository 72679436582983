import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Icon } from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import CustomizableTable from '../customizable-table/CustomizableTable';
import { useBillContext } from "../../context/BillContext";
import { useUserContext } from "../../context/UserContext";

const BillsView: React.FC = () => {
    const { bills, loading, error, updateBill } = useBillContext();
    const { users } = useUserContext();

    const handleBilledToggle = async (billId: number, currentBilledStatus: boolean) => {
        try {
            await updateBill(billId, { isBilled: !currentBilledStatus });
        } catch (error) {
            console.error('Error updating bill status:', error);
        }
    };

    const fields: any = {
        id: { label: 'Bill ID' },
        publishedAt: { label: 'Date', filterType: 'dateRange' },
        price: { label: 'Price' },
        'user.email': { label: 'User Email', filterField: 'user.email' },
        'user.dni': { label: 'User DNI', filterField: 'user.dni' },
        'user.name': { label: 'User Name', filterField: 'user.name' },
        isBilled: {
            label: 'Billed',
            filterField: 'isBilled',
            disableFilter: true,
        },
    };

    const getUser = (userId: number) => {
        return users.find((user: any) => user.id == userId);
    };

    return (
        <Box bg="white" p={4} borderRadius="lg" boxShadow="lg" overflowX="auto">
            {loading ? (
                <Box>Loading...</Box>
            ) : error ? (
                <Box>Error: {error}</Box>
            ) : (
                <CustomizableTable
                    data={bills.map((bill: any) => ({
                        id: bill.id,
                        publishedAt: new Date(bill.publishedAt).toLocaleString(),
                        price: `$${bill.price.toFixed(2)}`,
                        user: {
                            email: getUser(bill.user?.id)?.email || '',
                            dni: getUser(bill.user?.id)?.dni || '',
                            name: `${getUser(bill.user?.id)?.name} ${getUser(bill.user?.id)?.lastName}`,
                        },
                        isBilled: (
                            <Checkbox
                                isChecked={bill.isBilled}
                                onChange={() => handleBilledToggle(bill.id, bill.isBilled)}
                                icon={<Icon as={bill.isBilled ? CheckIcon : CloseIcon} color={bill.isBilled ? 'green.500' : 'red.500'} />}
                            />
                        ),
                    }))}
                    fields={fields}
                />
            )}
        </Box>
    );
};

export default BillsView;