import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from "../providers/AuthContext";
import { useUserContext } from "../context/UserContext";

interface RequireAuthProps {
    children: React.ReactNode;
    requiredRole?: string;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children, requiredRole }) => {
    const { jwt } = useAuth();
    const { currentUser, fetchCurrentUserData } = useUserContext();

    useEffect(() => {
        if (jwt && !currentUser) {
            fetchCurrentUserData();
        }
    }, [jwt, currentUser, fetchCurrentUserData]);

    if (!jwt) {
        console.log("No JWT found, redirecting to /login");
        return <Navigate to="/login" replace />;
    }

    const hasRequiredRole = !(requiredRole && currentUser && currentUser.role.name !== requiredRole);

    if (!hasRequiredRole) {
        console.log(`User does not have the required role: ${requiredRole}, redirecting to /`);
        return <Navigate to="/" replace />;
    }

    return <>{children}</>;
};

export default RequireAuth;
