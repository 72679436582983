import React from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';

interface DownloadButtonProps {
    onDownload: (format: 'csv' | 'xlsx') => void;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ onDownload }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button leftIcon={<DownloadIcon />} onClick={onOpen}>
                Download
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Download Data</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Button width="full" my={2} onClick={() => { onDownload('csv'); onClose(); }}>
                            Download as CSV
                        </Button>
                        <Button width="full" my={2} onClick={() => { onDownload('xlsx'); onClose(); }}>
                            Download as XLSX
                        </Button>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DownloadButton;
