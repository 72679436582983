import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, Text, useToast, Flex, Spinner } from '@chakra-ui/react';
import { useApiContext } from '../../providers/ApiProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { DispenseForm } from './DispenseForm';
import { DispenseConfirmation } from './DispenseConfirmation';
import { DispenseFormData, Product } from "./DispenseInterfaces";
import { useProductContext } from '../../context/ProductContext';

export const NewDispense: React.FC = () => {
    const location = useLocation();
    const { data, fetchData, createEntity } = useApiContext();
    const user = location.state?.user;
    const toast = useToast();
    const navigate = useNavigate();
    const { products, loading } = useProductContext();  // Using ProductContext to access products and loading state

    const [dispenseData, setDispenseData] = useState<DispenseFormData[]>([{ grams: 0, genetic: '', price: 0, isEditingPrice: false, comments: '' }]);
    const [medioDePago, setMedioDePago] = useState('');
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showComment, setShowComment] = useState(false);

    const productOptions = useMemo(() => {
        return products
    }, [products]);

    const totalPrice = useMemo(() => {
        return dispenseData.reduce((total, dispense) => total + dispense.price, 0);
    }, [dispenseData]);

    if (loading) {
        return (
            <Flex direction="column" align="center" justify="center" minHeight="400px">
                <Spinner size="xl" />
            </Flex>
        );
    }

    const handleInputChange = (index: number, field: keyof DispenseFormData, value: string | boolean) => {
        const newDispenseData = [...dispenseData];

        let grams = newDispenseData[index].grams;
        let genetic = newDispenseData[index].genetic;

        if (field === 'grams') {
            grams = Number(value);
            const product = productOptions.find(option => option.id == genetic);
            newDispenseData[index].grams = grams;
            newDispenseData[index].price = calculatePrice(grams, product || null);
        } else if (field === 'genetic') {
            genetic = value as string;
            const product = productOptions.find(option => option.id == genetic);
            console.log(product);
            newDispenseData[index].genetic = genetic;
            newDispenseData[index].price = calculatePrice(grams, product || null);
        } else if (field === 'isEditingPrice') {
            newDispenseData[index].isEditingPrice = value === 'true';
        } else {
            // Handle number and string fields distinctly
            if (field === 'price') {
                // Explicitly handle price as a number
                newDispenseData[index].price = Number(value);
            } else {
                // Use a type assertion to reassure TypeScript about the assignment
                newDispenseData[index][field] = value as unknown as typeof newDispenseData[number][typeof field];
            }
        }

        setDispenseData(newDispenseData);
    };

    const handleAddDispense = () => {
        setDispenseData([...dispenseData, { grams: 0, genetic: '', price: 0, isEditingPrice: false, comments: '' }]);
    };

    const handleRemoveDispense = (index: number) => {
        const newDispenseData = [...dispenseData];
        newDispenseData.splice(index, 1);
        setDispenseData(newDispenseData);
    };

    const handleSubmit = async () => {
        const newErrors: { [key: string]: boolean } = {};

        dispenseData.forEach((dispense, index) => {
            if (dispense.grams === 0) {
                newErrors[`grams-${index}`] = true;
            }
            if (!dispense.genetic) {
                newErrors[`genetic-${index}`] = true;
            }
        });

        if (!medioDePago) {
            newErrors.medioDePago = true;
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            toast({
                title: 'Incomplete Fields',
                description: 'Please fill in all the required fields.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setIsProcessing(true); // Start processing

        try {
            for (const dispense of dispenseData) {
                const createdDispense = await createEntity('createDispense', {
                    precio: dispense.price,
                    medioDePago,
                    dateDispensed: new Date().toISOString(),
                    units: dispense.grams,
                    isBilled: false,
                    genetic: dispense.genetic,
                    user: user.id,
                    comments: dispense.comments, // Send comments to API
                });
            }
            setIsSubmitted(true);
        } catch (error) {
            toast({
                title: 'Error',
                description: 'An error occurred while submitting the dispense.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsProcessing(false); // End processing
        }
    };

    const calculatePrice = (grams: number, genetic: Product | null) => {
        if (genetic) {
            const { price, productType } = genetic;
            console.log(genetic);

            if (productType === 'Cannabis') {
                if (user.Plan === 'Cinco Gramos') {
                    const increasePercentage = 0.085;
                    const increasedPrice = price * (1 + increasePercentage);
                    const roundedPrice = Math.round(increasedPrice / 100) * 100;
                    return roundedPrice * grams;
                } else if (user.Plan === 'Diez Gramos') {
                    const increasePercentage = 0.0365;
                    const increasedPrice = price * (1 + increasePercentage);
                    const roundedPrice = Math.round(increasedPrice / 100) * 100;
                    return roundedPrice * grams;
                } else if (user.Plan === 'Quince Gramos+') {
                    return price * grams;
                }
            } else {
                return price * grams;
            }
        }

        return 0;
    };

    if (isSubmitted) {
        return (
            <DispenseConfirmation
                user={user}
                dispenseData={dispenseData}
                totalPrice={totalPrice}
                navigate={navigate}
                productOptions={productOptions}
            />
        );
    }

    return (
        <Box>
            <DispenseForm
                dispenseData={dispenseData}
                medioDePago={medioDePago}
                productOptions={productOptions}
                user={user}
                onInputChange={handleInputChange}
                onAddDispense={handleAddDispense}
                onRemoveDispense={handleRemoveDispense}
                onMedioDePagoChange={setMedioDePago}
                onSubmit={handleSubmit}
                totalPrice={totalPrice}
                errors={errors}
                isProcessing={isProcessing} // Pass the processing state to control the button
                showComment={showComment} // Pass the comment visibility state
                onShowCommentChange={setShowComment} // Pass the handler for comment checkbox
            />
        </Box>
    );
};
