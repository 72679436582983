import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    List,
    ListItem,
} from '@chakra-ui/react';

interface UserSelectionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSelectUser: (userId: string) => void;
    users: any[];
}

const UserSelectionModal: React.FC<UserSelectionModalProps> = ({
                                                                   isOpen,
                                                                   onClose,
                                                                   onSelectUser,
                                                                   users,
                                                               }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select User</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <List spacing={3}>
                        {users.map((user) => (
                            <ListItem key={user.id}>
                                <Button onClick={() => onSelectUser(user.id)}>{user.username}</Button>
                            </ListItem>
                        ))}
                    </List>
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default UserSelectionModal;
