import * as XLSX from 'xlsx';

export const exportToCSV = <T extends Record<string, any>>(
    data: T[],
    headers: { key: keyof T | string; label: string }[],
    filename: string = 'data_export.csv'
): void => {
    const csvRows: string[] = [];

    // CSV header
    csvRows.push(headers.map(header => `"${header.label}"`).join(','));

    // CSV body
    data.forEach(item => {
        const row = headers.map(({ key }) => {
            const value = getNestedValue(item, key as string);
            const escaped = typeof value === 'string' ? ('' + value).replace(/"/g, '""') : value; // Handle quotes and non-string data
            return `"${escaped}"`;
        }).join(',');
        csvRows.push(row);
    });

    // Convert array of strings to a single string
    const csvString = csvRows.join('\n');

    // Add BOM to the CSV string for UTF-8 encoding
    const bom = '\uFEFF';
    const csvStringWithBom = bom + csvString;

    // Create a Blob for the CSV file and trigger download
    const blob = new Blob([csvStringWithBom], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();

    window.URL.revokeObjectURL(url);
};

export const exportToXLSX = <T extends Record<string, any>>(
    data: T[],
    headers: { key: keyof T | string; label: string }[],
    filename: string = 'data_export.xlsx'
): void => {
    const worksheetData = [
        headers.map(header => header.label),
        ...data.map(item => headers.map(header => getNestedValue(item, header.key as string))),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, filename);
};

const getNestedValue = (obj: any, path: string): any => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};
